import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SpinnerLoading from "./../../components/common/spinnerLoading"

class Prodotti extends Component {
  state = {
    slug: "",
    products: [],
    category: { attributes: { name: "" } },
    loading: true,
  }

  componentDidMount = () => {
    const { products, category } = this.props.pageContext
    this.setState({ products, category, loading: false })
  }

  render() {
    const { products, loading, category } = this.state
    return (
      <Layout>
        <SEO
          title={category.attributes.name}
          description={category.attributes.name}
        />

        <div className="container">
          <div className={"row"}>
            <div className={"col-12"}>
              {loading && (
                <div className="my-5 py-5">
                  <SpinnerLoading />
                </div>
              )}

              {!loading && (
                <div>
                  <h1 className={"page-title"}>{category.attributes.name}</h1>

                  <ul className={"products-list list-unstyled row"}>
                    {products &&
                      products.map(product => (
                        <li
                          key={product.id}
                          className={
                            "products-list-item col-12 col-md-6 col-lg-4"
                          }
                        >
                          <article>
                            <header className={"products-list-item-header"}>
                              {product.relationships.media.data.length > 0 && (
                                <img
                                  src={
                                    product.relationships.media.data[0]
                                      .attributes.url
                                  }
                                  alt={product.attributes.title}
                                  className={"products-list-item-image"}
                                />
                              )}
                              <h3 className="products-list-item-title">
                                {product.attributes.title}
                              </h3>
                            </header>

                            <div
                              className={"products-list-item-body"}
                              dangerouslySetInnerHTML={{
                                __html: product.attributes.excerpt,
                              }}
                            />

                            <footer className={"products-list-item-footer"}>
                              <a
                                className="products-list-item-read-more btn btn-primary btn-sm"
                                href={
                                  "/prodotti/articolo/" +
                                  product.attributes.slug +
                                  "/"
                                }
                              >
                                Leggi di più
                              </a>
                            </footer>
                          </article>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Prodotti
